import * as React from 'react';
import FolderChooserComponent from './FolderChooserComponent';
import FolderOverviewComponent from './FolderOverviewComponent';

export default function DingScreen({localStorage, directory, onLoadFolderClicked, onFolderClosed}) {

  async function onLoadFolder(onLoadFolderClicked) {
    await onLoadFolderClicked()
  }

  if (directory) {
    return(
      <FolderOverviewComponent
        localStorage={localStorage}
        onLoadFolderClicked={ () => onLoadFolder(onLoadFolderClicked) }
        onFolderClosed={onFolderClosed}
        directory={directory}
        style={{display: 'flex', flexDirection: 'column'}}
      />
    )
  } else {
    return(
      <FolderChooserComponent
        onLoadFolderClicked={ () => onLoadFolder(onLoadFolderClicked) }
      />
    )
  }
}