import * as React from 'react';

import ThemeProvider from "@mui/system/ThemeProvider"
import Box from '@mui/material/Box';
import CssBaseline from "@mui/material/CssBaseline"
import { Routes, Route, HashRouter } from "react-router-dom";
import Interview from "../Interview/Interview.js" 
import InterviewReadonly from "../InterviewReadonly/InterviewReadonly" 
import TagIndex from "../TagIndex/TagIndex" 
import LocalStorage from '../../model/LocalStorage'
import DingScreen from '../DingScreen/DingScreen';
import TranscriptionScreen from '../TranscriptionScreen/TranscriptionScreen';
import './app.css'

import MiniDrawer from './Drawer.js'

export default function EditorApp({theme}) {
    const [localStorage, setLocalStorage] = React.useState(new LocalStorage())
    const [canDoInitialRender, setCanDoInitialRender] = React.useState(false)
    const [directory, setDirectory] = React.useState('')
    const [newBaseDirTrigger, setNewBaseDirTrigger] = React.useState(false)

    const tryOpenPersistedBaseDir = async () => {
        const gotPersistedBaseDir = await localStorage.tryOpenPersistedBaseDir()
        if (gotPersistedBaseDir) {
            setNewBaseDirTrigger(! newBaseDirTrigger)
            setDirectory(localStorage.getCurrentWorkingDirectory())
        }
        setCanDoInitialRender(true)
    }

    const onLoadFolderClicked = async (e) => {
        const gotStorageBaseDir = await localStorage.trySelectStorageBaseDir()   
        if (gotStorageBaseDir) {
            setNewBaseDirTrigger(! newBaseDirTrigger)
            setDirectory(localStorage.getCurrentWorkingDirectory())
        }
    }

    React.useEffect( () => {
        tryOpenPersistedBaseDir()
    }, [])
    
    const onFolderClosed = (e) => {
        localStorage.reset()
        setNewBaseDirTrigger(! newBaseDirTrigger)
        setDirectory(null)
    }

    if (!canDoInitialRender) {
        return(null)
    }

    return(
        <React.StrictMode>
        <ThemeProvider theme={theme}>
            <HashRouter>
            <Box width="100%" sx={{ display: 'flex' }}>
                <CssBaseline />
                {directory &&
                    <MiniDrawer
                        localStorage={localStorage}
                        newBaseDirTrigger={newBaseDirTrigger}
                    />
                }
                <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
                    <Routes>
                        <Route exact path="/app" element={
                            <DingScreen
                                localStorage={localStorage}
                                onLoadFolderClicked={onLoadFolderClicked}
                                onFolderClosed={onFolderClosed}
                                directory={directory}
                            />}
                        />
                        <Route exact path="/app/tagindex" element={
                            <TagIndex
                                localStorage={localStorage}
                            />}
                        />
                        <Route
                            exact path="/app/explorer/*"
                            element={<Interview
                                localStorage={localStorage}
                                newBaseDirTrigger={newBaseDirTrigger}
                            />}
                        />
                        <Route
                            exact path="/app/transcription"
                            element={<TranscriptionScreen
                                jobManager={localStorage.jobManager}
                            />}
                        />
                        <Route exact path="/interview-ro/" element={<InterviewReadonly />} />
                    </Routes>
                </Box>
            </Box>
            </HashRouter>
        </ThemeProvider>
        </React.StrictMode>
    )
}