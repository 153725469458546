import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import { grey } from '@mui/material/colors';
import { MinusCircle } from "@phosphor-icons/react";

export default function AlertModal({creditPopoverAnchorEl, handleCreditPopoverClose, remainingCreditList, handleCreditRemove}) {

    const creditPopoverOpen = Boolean(creditPopoverAnchorEl);

    const handleRemoveClick = (e) => {
        let creditId = e.target.closest("[data-creditid]").getAttribute('data-creditid')
        handleCreditRemove(creditId)
    }

    return(
        <Popover
            // id={id}
            open={creditPopoverOpen}
            anchorEl={creditPopoverAnchorEl}
            onClose={handleCreditPopoverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
        <TableContainer>
            <Table size="small" sx={{mb:2}}>
                <TableHead>
                    <TableRow sx={{backgroundColor: grey[100], userSelect: 'none'}}>
                        <TableCell sx={{pl:2, py:2}}>Credit Code</TableCell>
                        <TableCell sx={{pr:2, py:2}}>Remaining</TableCell>
                        <TableCell sx={{pr:2, py:2}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {remainingCreditList && Object.keys(remainingCreditList).map((creditId, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                {creditId}
                            </TableCell>
                            <TableCell>
                                {remainingCreditList[creditId]} minutes
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={handleRemoveClick} data-creditid={creditId}>
                                    <MinusCircle size={16} weight='bold' />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </Popover>
    )
}