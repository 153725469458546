import { MediaFile } from "./MediaFile"

// Return dict of {tag => [Annotation]}
export default class Tags {
    static createTagIndex = async function(localStorage) {
        const tagIndex = {}

        const mediaFileHandles = await localStorage.scanForMediaFiles()
        const mediaFiles = await Promise.all(
            mediaFileHandles.map( async (file) => {
                const mf = await MediaFile.fromFilePath(localStorage, file.filePath)
                // TODO: only need to load annotations
                await mf.loadFiles(localStorage)
                return mf
            })
        )
    
        Object.values(mediaFiles).forEach(mf => {
            mf.annotations.forEach(annotation => {
                // Add these properties to Annotation
                annotation.mediaFile = mf
    
                if (!annotation.tags)
                    return;
                
                annotation.tags.forEach(tag => {
                if (! tagIndex[tag]) {
                    tagIndex[tag] = [annotation]
                } else {
                    tagIndex[tag].push(annotation)
                }
                })
            })
        })
    
        return tagIndex
    }
}
