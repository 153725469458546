import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { PlusCircle, Waveform } from "@phosphor-icons/react";
import { CreditCodes } from '../../model/CreditCodes'
import { TimeUtil } from "../../model/TimeUtil";


export default function OrderOverview({
        selectedMedia,
        mediaDuration,
        handleClose,
        handleConfirm,
        handleAddCredit,
        recalculateTrigger
    }) {

    const [remainingMinutes, setRemainingMinutes] = React.useState(0)
    const [costMinutes, setCostMinutes] = React.useState()
    const [insufficientCredit, setInsufficientCredit] = React.useState(true)

    const recalculate = async () => {
        const remainingCredits = await CreditCodes.getRemainingCredits()
        var remainingMinutes = 0
        Object.values(remainingCredits).map(m => remainingMinutes += m)
        setRemainingMinutes(remainingMinutes)

        const cost = TimeUtil.roundUpSecondsToMinutes(mediaDuration)
        setCostMinutes(cost)
        setInsufficientCredit(cost > remainingMinutes)
    }
    React.useEffect( () => {
        recalculate()
    }, [mediaDuration, recalculateTrigger])

    return(
        <Box>
            <Box sx={{ p: 3, backgroundColor: grey[100], display: 'flex', alignItems: 'center'}}>
                <h2 style={{margin: 0, lineHeight: 1, flexGrow: 1 }}>
                    Order Transcription
                </h2>
                <Button disabled>
                    {remainingMinutes} minutes credit
                </Button>
            </Box>

            <Divider />

            <Box sx={{p: 3, display: 'flex', flexDirection: 'row'}}>
                <Typography sx={{flexGrow: 1}}><b>{selectedMedia.basename()}</b></Typography>
                <Typography>{costMinutes} minutes will be used</Typography>
            </Box>

            { insufficientCredit 
                ?  <Typography sx={{px:3, pb:3}}>
                    Insufficient credit. You will need to add more transcription credits to continue.
                </Typography>
                : <Typography sx={{px:3, pb:3}}>
                    This file will be uploaded to Ding for processing. Ding does not keep copies of your
                    data after the transcript has been returned to you.
                </Typography>
            }

            <Box sx={{pb: 3, px: 3, display: 'flex', flexDirection: 'column', alignItems:'end'}}>
                <Box>
                    <Button disableElevation variant="outlined" sx={{mr: 2}} onClick={handleClose}>Cancel</Button>
                    {insufficientCredit
                        ? <Button disableElevation variant="contained" onClick={handleAddCredit} startIcon={<PlusCircle />}>Add Credit</Button>
                        : <Button disableElevation variant="contained" onClick={handleConfirm} startIcon={<Waveform weight="bold" />}>Transcribe</Button>
                    }
                </Box>
            </Box>
        </Box>
    )
}
