import * as React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { Play } from "@phosphor-icons/react";
import { TimeUtil } from "../../model/TimeUtil";

export default function AnnotationComponent({annotation, index, onTimecodeClick}) {
    return(
        <Box
            className="annotation"
            sx={{
                p: 1,
                mb: 1,
                border: 1,
                borderColor: 'transparent',
                borderRadius: 1,
            }}>
            <Chip
                sx={{ mb: 0.5, mr: 0.5 }}
                icon={<Play size={12} weight="fill" />}
                size="small"
                onClick={ (e) => onTimecodeClick(e) }
                data-timecode={annotation.start}
                data-index={index}
                label={TimeUtil.secondsToDisplayFormat(annotation.start)} />
            { annotation.tags != undefined
                    ? annotation.tags.map((t) => (
                        <Chip size="small" label={t} sx={{mb: 0.5, mr: 0.5}} variant="outlined" key={t}></Chip>
                    ))
                    : null
            }
            <Typography>{annotation.comment}</Typography>
        </Box>
    )
}