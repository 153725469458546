import React from 'react';
import ReactDOM from 'react-dom/client';
import ThemeProvider from "@mui/system/ThemeProvider"
import CssBaseline from "@mui/material/CssBaseline"
import { Routes, Route, HashRouter } from "react-router-dom";
import { createTheme } from '@mui/material/styles';
import InterviewReadonly from "./components/InterviewReadonly/InterviewReadonly" 
import './titillium.css';
import EditorApp from './components/EditorApp/EditorApp';
import Homepage from "./components/Homepage/Homepage" 
import reportWebVitals from './reportWebVitals';
import { blue } from '@mui/material/colors';

const options = {
  viewonly: false
}

const theme = createTheme({
  typography: {
    fontFamily: [
      // 'ui-sans-serif', 'system-ui',
      'Asap',
      // 'Titillium Web',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

if (options.viewonly) {
  renderViewer()
} else if (window.location.hash.startsWith('#app')) {
  renderEditor()
} else {
  renderHomepage()
}

function renderViewer() {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <HashRouter>
            <Routes>
              <Route exact path="/:interviewName" element={<InterviewReadonly />} />
            </Routes>
          </HashRouter>
      </ThemeProvider>
    </React.StrictMode>
  )
}

function renderEditor() {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <EditorApp theme={theme}/>
  );
}

function renderHomepage() {
  document.getElementById("root").remove();
  const root = ReactDOM.createRoot(document.getElementById('homepage-root'));
  root.render(
    <Homepage theme={theme}/>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
