import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { grey } from '@mui/material/colors';
import { Typography } from '@mui/material';
import buildInfo from '../../buildInfo.js'
import { BellRinging, FolderOpen } from "@phosphor-icons/react";

export default function FolderChooserComponent({onLoadFolderClicked}) {
    return(
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{height:'100%'}}
      >
        <Grid item width="680px">

          <Box>
            <Typography
              variant='h5'
              sx={{
                mr: 'auto',
                ml: 1,
                fontSize: '32px',
                fontWeight: 700 }}>
                  <BellRinging size={24} weight='bold' style={{marginRight: '16px'}} />
                  DING!
            </Typography>
          </Box>

          <Grid container justifyContent="space-between" alignItems="center"
            sx={{
              p: 2,
              borderRadius: 2,
              borderColor: 'grey.200',
              borderWidth: '1px',
              borderStyle: 'solid',
              backgroundColor: 'grey.50',
              my: 4
            }}>
              <Grid item>
                To begin, choose a folder that contains your media
              </Grid>
              <Grid item>
                <Button
                  sx={{ ml: 1 }}
                  size="large"
                  disableElevation
                  variant="contained"
                  onClick={(e) => onLoadFolderClicked(e)}
                  startIcon={<FolderOpen weight="fill" />}>
                    Open folder
                </Button>
              </Grid>
          </Grid>

          <Box sx={{ px: 2, color: grey[400] }}>
            Ding will create a subfolder to store notes and transcriptions.
            Your files and notes remain on this device and are only sent to Ding servers when using the transcription service.
          </Box>
          <Box sx={{ mt: 8 }}>
            <Typography variant="caption" color={'white'}>Version {buildInfo.version}, built {buildInfo.date.toString()}</Typography>
          </Box>
        </Grid>
      </Grid>
    )
}