import * as IdbKeyval from 'idb-keyval'
const IDB_KEY_CREDIT_CODE = "billingReceiptCodes"

export class CreditCodes {
    static async getCreditCodes() {
        return await IdbKeyval.get(IDB_KEY_CREDIT_CODE) || []
    }
    
    static async addCreditCode(newReceiptCode) {
        await IdbKeyval.update(IDB_KEY_CREDIT_CODE, (codes) => {
            if (!codes) codes = []
            codes.push(newReceiptCode)
            return codes
        })
    }

    static async removeCreditCode(creditCode) {
        await IdbKeyval.update(IDB_KEY_CREDIT_CODE, (codes) => {
            if (!codes) codes = []
            return codes.filter((value) => value != creditCode)
        })
    }

    static async getRemainingCredits() {
        const remainingCredit = 0
        const codes = await CreditCodes.getCreditCodes()
        const res = await fetch('/checkCredits/' + codes.join(","))
        const remainingCredits = await res.json()
        return remainingCredits
    }
}
