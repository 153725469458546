import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { CaretRight, Folder, CaretDown, FileAudio, FileVideo } from "@phosphor-icons/react";
import ListItemIcon from '@mui/material/ListItemIcon';
import { grey } from '@mui/material/colors';
import { useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

function FilesystemItem({entry, index, sx={pl:1, py: '2px'}, currentFilePath, openTrigger, display='block' }) {
    const defaultOpen = currentFilePath && currentFilePath.startsWith(entry.filePath)
    const [isOpen, setIsOpen] = React.useState(defaultOpen)
    const [instanceOpenTrigger, setInstanceOpenTrigger] = React.useState(false)

    if (defaultOpen && !isOpen && openTrigger != instanceOpenTrigger) {
        setIsOpen(true)
    }
    if (openTrigger != instanceOpenTrigger) {
        setInstanceOpenTrigger(!instanceOpenTrigger)
    }

    return(
        <Box sx={{
            backgroundColor: entry.filePath === currentFilePath ? grey[200] : '',
            display: display
        }}>
            <Tooltip title={entry.filePath} placement="right">
                <ListItemButton
                    disableGutters
                    sx={sx}
                    key={index}
                    onClick={ () => {
                        if (entry.kind === 'directory')
                            setIsOpen(!isOpen)
                        else 
                            window.location.href = "#app/explorer/" + entry.filePath
                    }}
                    >
                    <ListItemIcon sx={{minWidth: 24}}>
                        { (entry.iconType === "directory") && (isOpen ? <CaretDown /> : <CaretRight />) }
                        { (entry.iconType === "audio") && <FileAudio /> }
                        { (entry.iconType === "video") && <FileVideo /> }
                    </ListItemIcon>
                    <ListItemText
                        sx={{ m: 0 }}
                        primary={entry.name}
                        primaryTypographyProps={{fontWeight: entry.filePath === currentFilePath ? 'bold' : ''}}>
                    </ListItemText>
                </ListItemButton>
            </Tooltip>
            {entry.children && entry.children.map( (e, index) => (
                <FilesystemItem
                    display={isOpen ? 'block' : 'none'}
                    entry={e}
                    key={index}
                    openTrigger={openTrigger}
                    sx={{pl: sx.pl + 1, py: sx.py}}
                    currentFilePath={currentFilePath}/>
            ))}
        </Box>
    )
}

export default function FileExplorerComponent({sx, localStorage, newBaseDirTrigger}) {
    const [directoryTree, setDirectoryTree] = React.useState([])
    const [currentFile, setCurrentFile] = React.useState('')
    const [openTrigger, setOpenTrigger] = React.useState(true) // Must be opposite FilesystemItem default

    const location = useLocation()
    React.useEffect( () => {
        const filename = location.pathname.split('/explorer/')[1]
        setCurrentFile(filename)
        setOpenTrigger(!openTrigger)
    }, [location])  
    
    const loadDirectory = async () => {
        const entries = await localStorage.directoryTree()
        setDirectoryTree(entries)
    }

    React.useEffect( () => {
        loadDirectory()
    }, [newBaseDirTrigger])

    return(
        <Box sx={sx}>
            <List dense={true} disablePadding>
            <ListItemButton disabled sx={{pl:1, pr:0, py: '2px', opacity: '1 !important'}}>
                <ListItemIcon sx={{minWidth: 24}}>
                    <Folder />
                </ListItemIcon>
                <ListItemText sx={{ m: 0 }} primary={localStorage.storageBaseDir.name} />
            </ListItemButton>

                {directoryTree.map( (e, index) => (
                    <FilesystemItem
                        entry={e}
                        key={index}
                        openTrigger={openTrigger}
                        currentFilePath={currentFile}  />
                    ))
                }
            </List>
        </Box>
    )
}