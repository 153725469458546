import Pollinator from 'pollinator'
import axios from 'axios';
import LocalStorage from './LocalStorage'
import { CreditCodes } from './CreditCodes'

export class JobManager {

    // jobId => Job
    jobs = {}
    isStarted = false;
    localStorage;
    listeners = [];

    // JobID -> AbortController (for active upload)
    abortControllers = {}

    // Hacks
    storeVersion = 0
    snapshotVersion = 0
    snapshot = {}

    load(localStorage, jsonString) {
        this.jobs = {}
        this.localStorage = localStorage
        this.poller = new Pollinator(this.checkForUpdates)
        this.poller.on(Pollinator.Event.POLL, this.onUpdate )
        this.poller.start()

        // TODO: proper deserialization function
        // TODO: check state of deserialized jobs, eg, retry upload

        if (!jsonString)
            return

        const input = JSON.parse(jsonString)
        for (const [key, value] of Object.entries(input)) {
            const job = new Job(value.mediaFilename, value.jobId, value.status)
            if (value.status === 'created') {
                // Upload did not complete
                job.status = 'failed'
            }
            if (value.progressPercent) {
                job.progressPercent = Number(value.progressPercent)
            }
            this.jobs[job.jobId] = job
        }
        this.notifyListeners()
    }

    checkForUpdates = () => {        
        const jobs = Object.values(this.jobs)
        if (jobs.length == 0)
            return
        const jobIdsToCheck = jobs
            .filter( (j) => (!j.status || j.status != "collected" && j.status != "failed" && j.status != "cancelled"))
            .map( (j) => j.jobId)
        if (jobIdsToCheck.length == 0)
            return
        return fetch('/jobStatus/' + jobIdsToCheck.join(","))
    }

    onUpdate = async (res) => {
        if (!res)
            return
        const result = await res.json()
        // status = { status: String, progressPercent: String}
        for (const [jobId, statusResult] of Object.entries(result)) {
            this.setStatus(jobId, statusResult.status)
            this.setProgress(jobId, statusResult.progressPercent)

            if (statusResult.status === "complete")
                this.jobs[jobId].collect(this.localStorage)
        }
        this.notifyListeners()
    }

    subscribe = (listener) => {
        this.listeners = [...this.listeners, listener];
        return () => {
            this.listeners = this.listeners.filter(l => l !== listener);
        };
    }

    getSnapshot = () => {
        if (this.storeVersion > this.snapshotVersion) {
            const snapshot = {}
            Object.values(this.jobs).map( j => {snapshot[j.jobId] = j} )
            this.snapshot = snapshot
            this.snapshotVersion = this.storeVersion
        }
        return this.snapshot
    }

    notifyListeners() {
        this.storeVersion++
        for (let listener of this.listeners) {
            listener();
        }
    }

    serialize() {
        return JSON.stringify(this.jobs)
    }

    saveJobs() {
        this.localStorage.saveJobs(this.serialize())
    }

    setStatus(jobId, status) {
        if (this.jobs[jobId])
            this.jobs[jobId].status = status
        this.saveJobs()
    }

    setProgress(jobId, progressPercent) {
        if (this.jobs[jobId])
            this.jobs[jobId].progressPercent = progressPercent
        this.saveJobs()
    }

    setUploadProgress = (jobId, uploadProgressPercent) => {
        this.jobs[jobId].uploadProgressPercent = uploadProgressPercent
        this.notifyListeners()
    }

    async add(mediaFilename) {
        const newJob = await Job.create(mediaFilename)
        if (!newJob) {
            alert("Could not start transcription.")
            // TODO: give reason
            return
        }
        this.jobs[newJob.jobId] = newJob
        this.saveJobs()
        const abortController = await newJob.upload(this.localStorage, this.setUploadProgress)
        this.abortControllers[newJob.jobId] = abortController
    }

    remove(jobId) {
        delete this.jobs[jobId]
        this.saveJobs()
        this.notifyListeners()
    }

    cancelUpload(jobId) {
        this.abortControllers[jobId].abort()
        this.setStatus(jobId, 'cancelled')
    }
}

export class Job {
    mediaFilename;  // String
    jobId;          // String
    status;         // String: new, uploading, working, complete, failed
    progressPercent;// Number
    uploadProgressPercent = 0;  // Number, not serialized

    constructor(mediaFilename, jobId=null, status=null) {
        this.mediaFilename = mediaFilename
        this.jobId = jobId
        this.status = status
    }

    // Should belong to MediaFile
    getIdForPath(pathString) {
        return pathString.replaceAll("/", "__")
    }

    statusStringForUser() {
        switch (this.status) {
            case "created":
                return "Uploading"
            case "uploaded":
            case "converted":
                return "Waiting to transcribe"
            case "working":
            case "complete":
                return "Transcribing"
            case "collected":
                return "Done"
            case "failed":
                return "Failed"
            case "cancelled":
                return "Cancelled"
        }
    }

    static async create(mediaFilename) {
        const newJob = new Job(mediaFilename)
        await newJob.getNewJobID()
        return newJob
    }

    async getNewJobID() {
        const res = await fetch('/newJob')
        const newJobResponse = await res.json()
        const jobId = newJobResponse.jobId
        this.jobId = jobId
    }

    async upload(localStorage, setUploadProgress) {
        let formData = new FormData()
        let creditCodes = await CreditCodes.getCreditCodes()
        formData.set("jobId", this.jobId)
        creditCodes.map(c => formData.append("creditId[]", c))
        const fh = await localStorage.loadFileFromPath(this.mediaFilename)
        const f = await fh[0].getFile()
        formData.append('file', f)

        const uploadController = new AbortController();
        axios.request({
            method: "post", 
            url: "/upload", 
            data: formData, 
            signal: uploadController.signal,
            onUploadProgress: (p) => {
              setUploadProgress(this.jobId, (p.loaded/p.total) * 100 )
            }
        }).catch( (error) => console.log(error) )
        return uploadController
    }

    async collect(localStorage) {
        fetch('/collectVTT/' + this.jobId)
            .then(response => response.text()) 
            .then( async(vttString) => {
                const destVttHandle = await localStorage.getTranscriptFileHandle(this.getIdForPath("/" + this.mediaFilename), true)
                await LocalStorage.writeFile(destVttHandle, vttString)
        });
    }
}