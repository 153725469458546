import * as React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Play } from "@phosphor-icons/react";
import VideoJS from '../VideoJSComponent/VideoJSComponent'
import TranscriptView from './TranscriptViewComponent'

const FileIndex = "https://dl.dropboxusercontent.com/scl/fi/8gpe7m5k30fhmtjok1j1l/interviews.json?rlkey=16ynzaqdm9327jf47cqlq576o&dl=1"

class InterviewReadonlyComponent extends React.Component {
    constructor(props) {
        super(props);
        this.interviewName = props.interviewName
        this.state = {
            error: null,
            isLoaded: false,
            files: {},
            mediaCursor: 0,
            mediaCursorTimestamp: 0,
            startTime: 0,
            annotations: [],
            transcript: [],
            vjsTrigger: 0,      // A hack to trigger an effect (play at timestamp) on the videojs componenet without
                                // any other state change
        };
    }

    componentDidMount() {
        fetch(FileIndex)
            .then(res => res.json())
            .then(
                (result) => {
                    let selected = result[this.interviewName]
                    this.setState({
                        isLoaded: true,
                        files: selected
                    });
                    this.fetchAnnotationAndTranscript(selected.transcript, selected.annotations)
                }
            )
    }

    fetchAnnotationAndTranscript(transcriptUrl, annotationsUrl) {
        fetch(transcriptUrl)
            .then(res => res.json())
            .then(
                result => {
                    this.setState({
                        transcript: result
                    });
                }
            )
        fetch(annotationsUrl)
            .then(res => res.json())
            .then(
                result => {
                    this.setState({
                        annotations: result
                    });
                }
            )
    }

    onCursorMove = (time) => {
        this.setState({
            mediaCursor: time.toFixed(2),
            mediaCursorTimestamp: time
        })
    }

    onTimecodeClick(e) {
        let mediasrc = e.target.closest("[data-mediasrc]").getAttribute('data-mediasrc')
        let timecode = e.target.closest("[data-timecode]").getAttribute('data-timecode')
        this.playAtTimecode(mediasrc, timecode)

        // TODO: Currently a bug. Make this work with one click, not two. Possibly need a side-effect.
        let transcriptFuture = document.getElementsByClassName("transcript-future")[0]
        if (transcriptFuture) {
            transcriptFuture.scrollIntoView({block: "center"});
        }
    }

    onTranscriptWordClick = (e) => {
        let timecode = e.target.closest("[data-timecode]").getAttribute('data-timecode')
        this.setState({
            startTime: timecode,
            vjsTrigger: this.state.vjsTrigger + 1
        })
        
    }

    playAtTimecode(mediasrc, timecode) {
        this.setState({
            startTime: timecode,
            vjsTrigger: this.state.vjsTrigger + 1
        })
    }

    render() {
        const { error, isLoaded } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div></div>;
        } else {
            return (
                <Container maxWidth="lg" sx={{ borderTop:4, display: 'flex' }}>
                    <Grid container direction="column">
                        <Grid item sx={{ flexShrink: 1}}>
                            <h2>{this.state.files.title}</h2>
                        </Grid>

                        <Grid item container sx={{ flexGrow: 1, mb: 4 }}>
                            <Grid item xs={6} container direction="column">
                                <Grid item sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                                    <div style={{display: 'block'}}>
                                        <VideoJS
                                            vjsTrigger={this.state.vjsTrigger}
                                            onCursorMove={this.onCursorMove}
                                            mediaSrc={this.state.files.mediaSrc}
                                            peaksSrc={this.state.files.peaksSrc}
                                            startTime={this.state.startTime} />
                                    </div>
                                    <div style={{ overflow: 'scroll', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                        <div>
                                            <TranscriptView
                                                transcript={this.state.transcript}
                                                mediaCursorTimestamp={this.state.mediaCursorTimestamp}
                                                onTranscriptWordClick={this.onTranscriptWordClick}>
                                            </TranscriptView>
                                        </div>
                                    </div>


                                </Grid>
                            </Grid>
                            <Grid item xs={6} sx={{ p: 2}} style={{ overflow: 'scroll'}}>

                                <div style={{ maxHeight: '1px'}}>
                                    <Typography variant="h6"><b>Annotations</b></Typography>
                                    {this.state.annotations.map((a, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                p: 1,
                                                mt: 1,
                                                mb: 1,
                                                border: 1,
                                                borderColor: 'grey.200',
                                                borderRadius: 1,
                                                backgroundColor: 'grey.50',
                                            }}
                                        >
                                            <Chip
                                                sx={{ mb: 1 }}
                                                icon={<Play size={12} weight="fill" />}
                                                size="small"
                                                onClick={ (e) => this.onTimecodeClick(e) }
                                                data-mediasrc={a.mediaName}
                                                data-timecode={a.start}
                                                data-index={index}
                                                label={a.start} />
                                            <div >
                                                {a.tags.map((t) => (
                                                    <Chip size="small" label={t} sx={{mr: 0.5}} variant="outlined" key={t}></Chip>
                                                ))}
                                            </div>
                                            <Typography>{a.comment}</Typography>
                                        </Box>
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            );
        }
    }
}

export default function InterviewReadonly() {
    let { interviewName } = useParams();

    return(
        <InterviewReadonlyComponent interviewName={interviewName}></InterviewReadonlyComponent>
    )
}