import * as React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import scrollIntoView from 'scroll-into-view-if-needed'
import { grey } from '@mui/material/colors';
import { CheckCircle, PencilSimpleLine, Waveform } from "@phosphor-icons/react";
import CircularProgress from '@mui/material/CircularProgress';
import CircularProgressWithLabel from '../Common/CircularProgressWithLabel'
import ContentEditable from '../Common/ContentEditablePlaintext.tsx'

/**
 * TODO: make this common with InterviewReadonly/TranscriptViewComponent
 */

function scrollTranscriptToCurrentLine() {
    let transcriptFuture = document.getElementsByClassName("transcript-future")[0]
    if (transcriptFuture) {
        let parentElement = document.getElementById("transcriptViewport")
        let scrollBehavior =  isVisible(transcriptFuture, parentElement) ? "smooth" : "auto"
        scrollIntoView(transcriptFuture, {block: "center", behavior: scrollBehavior});
    }
}

function isVisible (ele, container) {
    // From https://phuoc.ng/collection/html-dom/check-if-an-element-is-visible-in-a-scrollable-container/
    if (!ele || !container) return false;
    const { bottom, height, top } = ele.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;
};

function NoTranscript(onAddVttClick, onTranscribeClick) {
    return(
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                height:'100%',
                p: 2
            }}
        >
            <Grid item>
                <Button
                    startIcon={<Waveform />}
                    onClick={onTranscribeClick}
                    variant="outlined">
                    Transcribe
                </Button>
            </Grid>
            <Grid item sx={{mt: 4}}>
                <Button
                    onClick={onAddVttClick}
                    variant="text">
                    Select existing vtt file
                </Button>
            </Grid>
            <Grid item sx={{mt: 4, color: grey[400]}}>
                <Typography>Add a transcription to rapidly comprehend and navigate conversations.</Typography>
            </Grid>
        </Grid>
    )
}

function TranscriptIsProcessing(job) {
    return(
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={
                {height:'100%',
                p: 2
            }}
        >
            <Grid item>
                {job.status === 'created'
                    ? <CircularProgressWithLabel progress={job.uploadProgressPercent} color="inherit" style={{color: grey[400]}} /> 
                    : job.progressPercent
                        ? <CircularProgressWithLabel progress={job.progressPercent} color="inherit" style={{color: grey[400]}} /> 
                        : <CircularProgress value={job.progressPercent} color="inherit" style={{color: grey[400]}} />
                }
            </Grid>
            <Grid item sx={{mt: 4, color: grey[400]}}>
                <Typography>{job.statusStringForUser()}</Typography>
            </Grid>
        </Grid>
    )
}

function TranscriptLine({t, transcriptArray, index, mediaCursorTimestamp, onTranscriptWordClick, isEditing, persist}) {

    const contentText = React.useRef(t.text);
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);

    React.useEffect(() => {
        contentText.current = t.text
        forceUpdate()
      }, [isEditing]);

    let className = t.start < mediaCursorTimestamp ? "transcript-past" : "transcript-future"

    const nextTranscriptEntry = transcriptArray[index + 1]
    const nextStartTime = nextTranscriptEntry ? nextTranscriptEntry.start : -1;
    const nextClassName = nextStartTime < mediaCursorTimestamp ? "transcript-past" : "transcript-future"

    const handleChange = (e) => {
        contentText.current = e.target.value;
    }

    const handleBlur = () => {
        persist(index, contentText.current)
    }

    if (className === "transcript-past" && nextClassName === "transcript-future")
        className = "transcript-current"

    if (className === "transcript-past" && index === transcriptArray.length - 1)
        className = "transcript-current"

    const timestamp = (timestamp) => {
        return(
            <Typography
            variant="caption"
            style={{
                marginRight: '8px',
                opacity: '0.5',
                fontWeight: 'bold',
                borderRadius: 4,
                userSelect: 'none',
                padding: '4px',
                paddingTop: '2px' }}>
                    {timestamp}
            </Typography>
        )
    }

    return(
        <Box sx={{
                display: 'flex',
                padding: '4px',
                cursor: isEditing ? 'select' : 'pointer',
            }}
            className={"ts-line " + className}
            data-timecode={t.start}
            onClick={onTranscriptWordClick}>
                {timestamp(t.startFormatted)}
                <ContentEditable
                    disabled={!isEditing}
                    html={contentText.current} // innerHTML of the editable div
                    onChange={handleChange} // handle innerHTML change
                    tagName='span' // Use a custom HTML tag (uses a div by default)
                    style={{outline: 'none', width: '100%'}}
                    onBlur={handleBlur}
                />
        </Box>
    )
}

function Transcript({transcript, mediaCursorTimestamp, onTranscriptWordClick, updateTranscript}) {

    const [isEditing, setIsEditing] = React.useState(false)
    const editedTranscriptContent = React.useRef()

    // const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0);

    const onEditClicked = () => {
        setIsEditing(true)
        editedTranscriptContent.current = transcript.content.map(t => t.clone())
    }

    const onCancelClicked = () => {
        setIsEditing(false)
    }

    const onSaveClicked = () => {
        setIsEditing(false)
        updateTranscript(editedTranscriptContent.current)
    }

    const persist = (index, text) => {
        editedTranscriptContent.current[index].text = text
    }

    return(
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Box sx={{p:1, display: 'flex'}}>
                <Typography variant="h6" sx={{color: grey[700], flexGrow: 1}}><b>Transcript</b></Typography>
                {(isEditing)
                    ? <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography sx={{mr: 2, fontStyle: 'italic', color: grey[400]}} >Editing</Typography>
                        <Button sx={{mr: 1}} size="small" variant="outlined" onClick={onCancelClicked} >Cancel</Button>
                        <Button disableElevation size="small" variant="contained" onClick={onSaveClicked} startIcon={<CheckCircle size={16} />}>Save</Button>
                    </Box>
                    : <Button size="small" onClick={onEditClicked}startIcon={<PencilSimpleLine sixe={16} />}>Edit</Button>
                }
                
            </Box>
            <div id="transcriptViewport" style={{ overflowY: 'scroll', overflowX: 'hidden', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <div style={{maxHeight: '1px'}}>
                    {transcript.content.map((t, index, transcriptArray) => 
                        <TranscriptLine
                            t={t}
                            key={index}
                            transcriptArray={transcriptArray}
                            index={index}
                            persist={persist}
                            isEditing={isEditing}
                            mediaCursorTimestamp={mediaCursorTimestamp}
                            onTranscriptWordClick={onTranscriptWordClick}/>
                    )}
                </div>
            </div>
        </div>
    )
}

export default function TranscriptView({
        transcript, mediaCursorTimestamp, onTranscribeClick, onTranscriptWordClick,
        onAddVttClick, autoScroll, mediaFilename, jobManager, onTranscriptReturned, updateTranscript}) {
    if (autoScroll) {
        setTimeout( () => {
            scrollTranscriptToCurrentLine()
        }, 0)
    }

    const getTranscriptionJob = () => {
        const jobsList = jobManager.getSnapshot()
        const transcriptionJob = Object.values(jobsList).filter(
            (j) => j.mediaFilename === mediaFilename && j.status != 'failed' && j.status != 'cancelled'
        )[0]
        return transcriptionJob
    }

    const transcriptionJob = React.useSyncExternalStore(jobManager.subscribe, getTranscriptionJob);
    
    if (transcript === undefined && transcriptionJob && transcriptionJob.status === 'collected') {
        onTranscriptReturned()
    }

    if (transcript)
        return <Transcript
            transcript={transcript}
            mediaCursorTimestamp={mediaCursorTimestamp}
            updateTranscript={updateTranscript}
            onTranscriptWordClick={onTranscriptWordClick}/>
    if (transcriptionJob)
        return TranscriptIsProcessing(transcriptionJob)
    // transcript is undefined
    return NoTranscript(onAddVttClick, onTranscribeClick)
}