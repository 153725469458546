import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { X } from "@phosphor-icons/react";
import { grey } from '@mui/material/colors';
import { Typography } from '@mui/material';
import { CreditCodes } from '../../model/CreditCodes'

export default function AddCreditFlow({handleClose, onCreditAdded}) {

    const addCreditCode = (creditCodes) => {
        // TODO: sanitize/parse/verify
        CreditCodes.addCreditCode(creditCodes)
        onCreditAdded()
    }

    return(
        <Box>
            <Box sx={{ p: 3, backgroundColor: grey[100], display: 'flex', alignItems: 'center'}}>
                <h2 style={{margin: 0, lineHeight: 1, flexGrow: 1 }}>
                    Add Transcription Credit
                </h2>
                <IconButton onClick={handleClose}>
                    <X size={20} weight='bold' />
                </IconButton>
            </Box>

            <Divider />

            <Box sx={{p: 3}}>
                <Typography sx={{mb: 1, fontWeight: 'bold', textTransform: 'uppercase'}}>
                    Credit code
                </Typography>
                <Typography sx={{mb: 1}}>
                    Manually enter a credit code:
                </Typography>
                <Box sx={{display: 'flex'}}>
                    <TextField id="creditCodes" label="Credit Code" variant="standard" size="small" sx={{flexGrow: 1, mr: 2}}/>
                    <Button
                        variant="contained"
                        disableElevation sx={{ height: 36, alignSelf: 'end'}}
                        onClick={ () => addCreditCode(document.getElementById("creditCodes").value) }
                    >
                        Add
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
