import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';
import { Typography } from '@mui/material';
import WaveformData from 'waveform-data';

export default function PeakGeneratorComponent({mediaFile, localStorage, onPeaksWritten}) {

  // Make sure only called once, especially in StrictMode
  const started = React.useRef(false);

  const [currentFile, setCurrentFile] = React.useState("")

  const generatePeaks = async () => {
    setCurrentFile(mediaFile.fileHandle.name)

    const file = await mediaFile.fileHandle.getFile()
    const buffer = await file.arrayBuffer()

    const audioContext = new AudioContext({
      sampleRate: 3000
    });

    const options = {
      audio_context: audioContext,
      array_buffer: buffer,
      scale: 128
    };

    const waveformdata = await new Promise((resolve, reject) => {
      WaveformData.createFromAudio(options, (err, waveform) => {
        if (err) {
          reject(err);
        }
        else {
          resolve(waveform);
        }
      });
    });
    await localStorage.writePeaks(mediaFile, waveformdata.toJSON())
    onPeaksWritten()
  }

  React.useEffect( () => {
    if (started.current)
      return;
    else {
      started.current = true
      generatePeaks()
    }
  }, [])

  return(
    <Container fixed maxWidth="sm">
      <Box
        sx={{
          m: 4,
          border: 1,
          borderColor: 'grey.300',
          borderRadius: 1,
      }}>

        <Box sx={{
            bgcolor: 'background.paper',
            p: 2,
            borderRadius: 1,
            backgroundColor: 'grey.50',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        }}>
          <Typography>Loading audio: {currentFile}</Typography>
        </Box>
        
        <Divider></Divider>
        
        <Box
          sx={{
            p: 2
          }}>
          <LinearProgress />
          
        </Box>
      </Box>
    </Container>
  )
}

