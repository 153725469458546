import * as IdbKeyval from 'idb-keyval'
import { showDirectoryPicker } from 'native-file-system-adapter'
import { JobManager } from './JobManager'

const DING_SUBDIR = "ding"
export const NOTES_SUBDIR = "notes"
export const MEDIA_SUBDIR = "media"
export const PEAKS_SUBDIR = "peaks"
export const TRANSCRIPT_SUBDIR = "transcripts"
export const JOBS_FILE = "jobs.json"

const IDB_KEY_STORAGE_BASE_DIR_HANDLE = "storageBaseDir"

export default class LocalStorage {
    storageBaseDir;
    jobManager = new JobManager()

    async tryOpenPersistedBaseDir() {
        const storageBaseDir = await IdbKeyval.get(IDB_KEY_STORAGE_BASE_DIR_HANDLE)
        try {
            const gotPermission = await this.verifyPermission(storageBaseDir)
            const gotPersist = await this.verifyPersist()
            if (!gotPermission || !gotPersist) {
                return false;
            }
            await storageBaseDir.getDirectoryHandle(NOTES_SUBDIR, {create: true})
            this.storageBaseDir = storageBaseDir
            await this.onDirectoryOpened()
        } catch (e) {
            if (e.name === "NotFoundError") {
                // Directory no longer exists (eg. now deleted or unmounted)
                return false
            }
        }
        return (this.storageBaseDir ? true : false)
    }

    // Return true if directory was selected
    async trySelectStorageBaseDir(onSuccess) {
        try {
            this.storageBaseDir = await showDirectoryPicker()
            IdbKeyval.set(IDB_KEY_STORAGE_BASE_DIR_HANDLE, this.storageBaseDir)
            await this.onDirectoryOpened()
            return true
        } catch (e) {
            if (e.name === "AbortError") {
                console.log("User aborted folder selection in trySelectStorageBaseDir()")
            }
        }
        return false
    }

    async verifyPermission(fileHandle, readWrite) {
        // https://developer.chrome.com/docs/capabilities/web-apis/file-system-access#stored_file_or_directory_handles_and_permissions
        const options = {};
        if (readWrite) {
          options.mode = 'readwrite';
        }
        // Check if permission was already granted. If so, return true.
        if ((await fileHandle.queryPermission(options)) === 'granted') {
          return true;
        }
        // Request permission. If the user grants permission, return true.
        if ((await fileHandle.requestPermission(options)) === 'granted') {
          return true;
        }
        // The user didn't grant permission, so return false.
        return false;
    }

    async verifyPersist() {
        // Request persistent storage for site
        if (navigator.storage && navigator.storage.persist) {
            const isPersisted = await navigator.storage.persist();
            return isPersisted
        }
        return false;
    }

    async onDirectoryOpened() {
        // Load jobs file 
        this.loadJobManager()
    }

    async reset() {
        this.storageBaseDir = null
        await IdbKeyval.del(IDB_KEY_STORAGE_BASE_DIR_HANDLE)
    }

    async copyFile(sourceFileHandle, destFileHandle) {
        const sourceFile = await sourceFileHandle.getFile()
        const destWritable = await destFileHandle.createWritable()
        await sourceFile.stream().pipeTo(destWritable)
    }

    /**
     * @returns String of the directory user has selected as base dir.
     */
    getCurrentWorkingDirectory() {
        return this.storageBaseDir.name
    }

    async loadJobManager() {
        const dingDir = await this.storageBaseDir.getDirectoryHandle(DING_SUBDIR, { create: true })
        const jobManagerFileHandle = await dingDir.getFileHandle(JOBS_FILE, { create: true })
        const jobManagerFile = await jobManagerFileHandle.getFile()
        const jobManagerJsonString = await LocalStorage.readFileAsync(jobManagerFile)
        this.jobManager.load(this, jobManagerJsonString)
    }

    async saveJobs(serializedJobs) {
        const dingDir = await this.storageBaseDir.getDirectoryHandle(DING_SUBDIR, { create: true })
        const jobManagerFileHandle = await dingDir.getFileHandle(JOBS_FILE, { create: true })
        LocalStorage.writeFile(jobManagerFileHandle, serializedJobs)
    }

    async getPeaksFileHandle(mediaID) {
        const dingDir = await this.storageBaseDir.getDirectoryHandle(DING_SUBDIR, { create: true })
        const peaksDir = await dingDir.getDirectoryHandle(PEAKS_SUBDIR, { create: true })
        try {
            return await peaksDir.getFileHandle(mediaID + ".json", { create: false })
        } catch (e) {
            if (e.name === "NotFoundError")
                return null
        }
        return null
    }

    async getTranscriptFileHandle(mediaID, shouldCreate) {
        const dingDir = await this.storageBaseDir.getDirectoryHandle(DING_SUBDIR, { create: true })
        const transcriptDir = await dingDir.getDirectoryHandle(TRANSCRIPT_SUBDIR, { create: true })
        try {
            return await transcriptDir.getFileHandle(mediaID + ".vtt", { create: shouldCreate || false })
        } catch (e) {
            if (e.name === "NotFoundError")
                return null
        }
        return null
    }

    async getAnnotationsFileHandle(mediaID, shouldCreate) {
        const dingDir = await this.storageBaseDir.getDirectoryHandle(DING_SUBDIR, { create: true })
        const notesDir = await dingDir.getDirectoryHandle(NOTES_SUBDIR, { create: true })
        try {
            return await notesDir.getFileHandle(mediaID + ".notes.json", { create: shouldCreate || false })
        } catch (e) {
            if (e.name === "NotFoundError")
                return null
        }
        return null
    }

    static readFileAsync(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsText(file);
        })
    }

    /**
     * Returns filename to peaks.json
     */
    async writePeaks(mediaFile, peaksData) {
        const dingDir = await this.storageBaseDir.getDirectoryHandle(DING_SUBDIR, { create: true })
        const peaksDir = await dingDir.getDirectoryHandle(PEAKS_SUBDIR, { create: true })
        const filename = mediaFile.id + ".json"
        const peaksFile = await peaksDir.getFileHandle(filename, { create: true })
        await LocalStorage.writeFile(peaksFile, JSON.stringify(peaksData))
    }

    static async writeFile(fileHandle, contents) {
      const writable = await fileHandle.createWritable();
      await writable.write(contents);
      await writable.close();
    }

    makeFilePath = (file, pathArray) => {
        if (pathArray.length === 0)
            return file.name
        return pathArray.join("/") + "/" + file.name 
    }

    async scanDir(dir, path, recursive = false) {
        const files = []

        const sortFunction = ( (a, b) => {
            // Both folders or both files, sort by name
            if ( (a.kind === "directory" && b.kind === "directory"))
                return a.name.localeCompare(b.name)
            // Only one is folder, sort by that one
            if (a.kind === "directory")
                return -1
            if (b.kind === "directory")
                return 1
            // Both files, sort by name
            return a.name.localeCompare(b.name)
        })

        const toArray = async (asyncIterator) => { 
            const arr=[]; 
            for await(const i of asyncIterator) arr.push(i); 
            return arr;
        }

        const values = await toArray(dir.values())
        values.sort(sortFunction)
        for await (const entry of values) {
            const nextPath = (dir === this.storageBaseDir) ? [] : [...path, dir.name]
            entry.path = nextPath
            // Do some filtering
            if (entry.name.startsWith('.'))
                continue;
            if (entry.kind === "directory") {
                entry.iconType = 'directory'
                if (recursive) {
                    const subdirfiles = await this.scanDir(entry, nextPath, true)
                    files.push(...subdirfiles)
                } else {
                    files.push(entry)
                }
            }
            const extension = entry.name.includes('.') ? entry.name.split('.').pop().toLowerCase() : ''
            entry.path = nextPath                                   // Array format
            entry.filePath = this.makeFilePath(entry, nextPath)     // String format
            if (['mp3', 'm4a', 'ogg', 'wav'].includes(extension)) {
                entry.iconType = 'audio'
                files.push(entry)
                continue;
            }
            if (['mp4', 'mov', 'ogv'].includes(extension)) {
                entry.iconType = 'video'
                files.push(entry)
                continue;
            }
        }

        return files
    }

    async directoryTree() {
        const populateChildren = async (dir) => {
            const files = await this.scanDir(dir, dir.path)
            for await (const file of files) {
                if (file.kind === 'directory')
                    await populateChildren(file)
            }
            dir.children = files
            return files
        }
        return await populateChildren(this.storageBaseDir)
    }

    async scanForMediaFiles() {
        if (!this.storageBaseDir)
            return []
        const allFiles = await this.scanDir(this.storageBaseDir, [], true)
        return allFiles.filter( (f) => f.kind !== "directory")
    }

    /**
     * Returns file
     * @param {*} pathString 
     */
    async loadFileFromPath(pathString) {
        if (!pathString || pathString.length === 0)
            return [null, null]
        const path = pathString.split("/").reverse()
        const pathArray = []
        let handle = this.storageBaseDir
        while (path.length > 1) {
            const dir = path.pop()
            pathArray.push(dir)
            handle = await handle.getDirectoryHandle(dir)
        }
        handle = await handle.getFileHandle(path.pop())
        return [handle, pathArray]

    }
}
