import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { X } from "@phosphor-icons/react";
import { grey } from '@mui/material/colors';

export default function AddCreditModal({
    isOpen,
    title,
    message,
    handleClose,
    positiveButtonLabel,
    handleConfirm}
) {

    return(
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                background: 'white',
                color: grey[700],
                width: '600px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -80%)',
                borderRadius: 1,
                overflow: 'hidden',
                userSelect: 'none'

            }}>
                <Box sx={{ p: 3, backgroundColor: grey[100], display: 'flex', alignItems: 'center'}}>
                    <h2 style={{margin: 0, lineHeight: 1, flexGrow: 1 }}>
                        {title}
                    </h2>
                    <IconButton onClick={handleClose}>
                        <X size={20} weight='bold' />
                    </IconButton>
                </Box>

                <Divider />

                <Box sx={{p: 3}}>
                    {message}
                </Box>

                <Box sx={{pb: 3, px: 3, display: 'flex', flexDirection: 'column', alignItems:'end'}}>
                    <Box>
                        <Button disableElevation variant="outlined" sx={{mr: 2}} onClick={handleClose}>Cancel</Button>
                        <Button disableElevation variant="contained" onClick={handleConfirm}>{positiveButtonLabel}</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}
