import { Box } from '@mui/material';
import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Timer } from "@phosphor-icons/react";
import { TimeUtil } from "../../model/TimeUtil";
import { styled } from '@mui/material/styles';

export default function AnnotationEditor({
        selectedAnnotation,
        allTags,
        mediaCursor,
        onCancelClicked,
        onDeleteClicked,
        onSaveClicked}) {

    const [annotation, setAnnotation] = React.useState(selectedAnnotation)

    function onTagsChange(event, selectedTags) {
         // We added an empty string tag at the start which we use to render the timestamp chip
         // Filter it out before saving
        const filteredTags = selectedTags.filter( t => (t) )
        setAnnotation({
            ...annotation,
            tags: filteredTags
        })
    }

    function onCommentChange(event) {
        const comment = event.target.value
        setAnnotation({
            ...annotation,
            comment: comment
        })
    }

    function updateTime() {
        setAnnotation({
            ...annotation,
            start: mediaCursor
        })
    }

    const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
        '& .MuiFormControl-root': {
            backgroundColor: 'transparent',
        },
        '& .MuiInput-root::before': {
            border: 0
        },
        '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
            border: 0
        },
        '& .MuiInput-root:hover::before': {
            border: 0
        },
        '& .MuiInput-root::after': {
            border: 0
        },
        '& .MuiAutocomplete-root:hover::after': {
            border: 0
        },
    }))

    return(
        <Box sx={{
            p: 1,
            backgroundColor: 'grey.50',
            border: 1,
            borderColor: 'grey.200',
            borderRadius: 1,
            mb: 1
        }}>
            <StyledAutocomplete
                fullWidth
                size="small"
                multiple
                freeSolo
                id="tags-outlined"
                options={allTags}
                value={["", ...annotation.tags] || []}
                onChange={onTagsChange}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        className="textField"
                        placeholder=" Add (more) tags..."
                    />
                )}
                renderTags={(tagValues, getTagProps) => (
                    tagValues.map((option, index) => (
                        <div>
                            {(option === "")
                                ? <Chip
                                    size="small"
                                    label={TimeUtil.secondsToDisplayFormat(annotation.start)}
                                    sx={{mr: 0.5}}
                                    variant="outlined"
                                    icon={<Timer />}
                                />
                                : <Chip
                                    size="small"
                                    label={option}
                                    {...getTagProps({ index })}
                                />
                            }
                        </div>
                    ))
                )}
            />
            <TextField
                sx={{mt: 2}}
                id="outlined-multiline-flexible"
                placeholder="Comment"
                size="small"
                multiline
                fullWidth
                className="textField"
                value={annotation.comment || ""}
                onChange={onCommentChange}
                maxRows={6}
            />

            <Grid container alignItems="center" sx={{mt:2}}>
                <Grid item xs={6}>
                    {(mediaCursor !== annotation.start)
                        ? <Button
                            disableElevation
                            size="small"
                            onClick={updateTime}>
                                Set time to {TimeUtil.secondsToDisplayFormat(mediaCursor)}
                            </Button>
                        : null
                    }
                </Grid>

                <Grid item xs={6}>
                    <div style={{float: 'right'}}>
                        <Button
                            sx={{mr: 1}}
                            size="small"
                            onClick={onCancelClicked}
                            disableElevation>
                                Cancel
                        </Button>
                        {(!annotation.isNew)
                            ? <Button
                                sx={{mr: 1}}
                                size="small"
                                onClick={onDeleteClicked}
                                disableElevation>
                                    Delete
                            </Button>
                            : null
                        }
                        <Button
                            disableElevation
                            size="small"
                            onClick={ () => onSaveClicked(annotation)}
                            variant="contained">
                                Save
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}