import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { grey } from '@mui/material/colors';
import Link from '@mui/material/Link';
import { CaretLeft, Folder, TagSimple, List as ListIcon, Waveform } from "@phosphor-icons/react";
import FileExplorerComponent from './FileExplorerComponent';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer({localStorage, newBaseDirTrigger}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpenClose = () => {
    setOpen(!open)
  }

  return (
    <Drawer variant="permanent" open={open} sx={{ display: 'flex', height: '100%' }} PaperProps={{ sx: {backgroundColor: '#eaf0f6'} }}>
      <DrawerHeader sx={{ backgroundColor: theme.palette.primary.main }}>
        <Typography
          variant='h5'
          sx={{
            mr: 'auto',
            ml: 1,
            color: theme.palette.grey[200],
            fontWeight: 700,
            visibility: open ? 'visible' : 'hidden' }}>
              DING!
        </Typography>
        <IconButton onClick={handleDrawerOpenClose}>
          {!open && <ListIcon color={theme.palette.grey[200]}/>}
          {open && (theme.direction === 'rtl' ? <CaretLeft color={theme.palette.grey[200]}/> : <CaretLeft weight="bold" size={16} style={{ color: theme.palette.grey[200] }}/>)}
        </IconButton>
      </DrawerHeader>

      <List disablePadding>
        <Link href="#app/" underline='none'>
          <ListItemButton>
              <ListItemIcon>
                <Folder size={24} color={theme.palette.primary.main} />
              </ListItemIcon>
                <ListItemText sx={{ color: "primary.main"}}>Overview</ListItemText>
          </ListItemButton>
        </Link>
          <Link href="#app/tagindex" underline='none'>
              <ListItem disablePadding>
              <ListItemButton>
                  <ListItemIcon>
                    <TagSimple size={24} color={theme.palette.primary.main} />
                  </ListItemIcon>
                  <ListItemText>Tags</ListItemText>
              </ListItemButton>
              </ListItem>
          </Link>
        <Link href="#app/transcription" underline='none'>
            <ListItem disablePadding>
            <ListItemButton>
                <ListItemIcon>
                  <Waveform size={24} color={theme.palette.primary.main} />
                </ListItemIcon>
                <ListItemText>Transcription</ListItemText>
            </ListItemButton>
            </ListItem>
        </Link>
      </List>

      <Divider sx={{mb:1}}/>
      
      <FileExplorerComponent
        width={drawerWidth}
        localStorage={localStorage}
        newBaseDirTrigger={newBaseDirTrigger}
        sx={{
          visibility: open ? 'visible' : 'hidden',
          flexGrow: 1,
          height: '0px',
          overflowY: 'scroll',
          overflowX: 'hidden',
          color: grey[700]
        }}
      />

    </Drawer>
  );
}