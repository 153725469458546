import LocalStorage from './LocalStorage'

export class Annotation {

    // {
    //     "name":"Phyllis",
    //     "mediaName":"Phyllis/PhyllisCombined.m4a",
    //     "start":"1371.23",
    //     "comment":"Maurice Connors another woman after their mother, who Phyllis met at Rocky Creek Hospital.",
    //     "tags":[]
    // }

    name;
    mediaName;
    start;
    comment;
    tags = [];

    constructor({name, mediaName, start, comment, tags}) {
        this.name = name;
        this.mediaName = mediaName;
        this.start = start;
        this.comment = comment || "";
        this.tags = tags || [];
    }

    // Returns array of Annotation
    static async readFromFile(annotationsFileHandle) {
        if (annotationsFileHandle === null) {
            return []
        }
        const annotationsFile = await annotationsFileHandle.getFile()
        const annotationsFileData = await LocalStorage.readFileAsync(annotationsFile)
        
        if (annotationsFileData === '') {
            return []
        }
        const fileContents = JSON.parse(annotationsFileData)
        const annotations = fileContents.map(a => new Annotation(a))
        return annotations
    }

    static async writeToFile(annotationsFileHandle, annotations) {
        await LocalStorage.writeFile(annotationsFileHandle, JSON.stringify(annotations))
    }
}