import * as React from 'react';
import { Typography } from '@mui/material';

/**
 * TODO: make this common with Interview/TranscriptViewComponent
 */

function Word({start, text, onClick, className}) {
    return(
        <span data-timecode={start} onClick={onClick} className={className}>{text}<br></br></span>
    )
}

export default function TranscriptView({transcript, mediaCursorTimestamp, onTranscriptWordClick}) {

    const renderedTranscript = transcript.map((t, index) => {
        const className = t.start < mediaCursorTimestamp ? "transcript-past" : "transcript-future"
        return(<Word key={index} start={t.start} text={t.text} onClick={onTranscriptWordClick} className={className}></Word>)
    })

    return(
        <div style={{maxHeight: '1px'}}>
            <Typography variant="h6"><b>Transcript</b></Typography>
            {renderedTranscript}
        </div>
    )
}