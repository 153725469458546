import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Folder, FolderOpen } from "@phosphor-icons/react";
import { grey } from '@mui/material/colors';
import FileTable from './FileTable'
import { MediaFile } from '../../model/MediaFile'

export default function FolderOverviewComponent({localStorage, directory, onLoadFolderClicked, onFolderClosed}) {

  const [allMediaFiles, setAllMediaFiles] = React.useState([])

  const loadFiles = async () => {
    const files = await localStorage.scanForMediaFiles()
    const mediaFiles = await Promise.all(files.map( async (f) => {
      const mf = await MediaFile.fromFilePath(localStorage, f.filePath)
      await mf.loadFiles(localStorage)
      return mf
    }))
    setAllMediaFiles(mediaFiles)
  }

  React.useEffect( () => { loadFiles() }, [directory])

  return(
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%'}}>
      <Grid container justifyContent="space-between" alignItems="center"
        sx={{
          minHeight: '64px',
          height: '64px',
          px: 4,
          backgroundColor: grey[50],
        }}>
          <Grid item>
            <h2 style={{margin: 0, lineHeight: 1, color: grey[700], display: 'flex', alignItems: 'center'}}>
              <Folder style={{marginRight: '24px'}}/> { directory }
            </h2>
          </Grid>
          <Grid item>
              <Button
                sx={{ mr: 1 }}
                disableElevation
                variant="outlined"
                onClick={() => onFolderClosed()}>
                  Close
              </Button>
              <Button
                disableElevation
                variant="contained"
                onClick={(e) => onLoadFolderClicked(e)}
                startIcon={<FolderOpen weight="fill" />}>
                  Open different folder
              </Button>
          </Grid>
      </Grid>
      <FileTable mediaFiles={allMediaFiles} />
    </Box>
  )
}