import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { grey } from '@mui/material/colors';
import AddCreditFlow from '../Common/AddCreditFlow'

export default function AddCreditModal({isOpen, handleClose, handleAddCredit}) {

    return(
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    background: 'white',
                    color: grey[700],
                    width: '600px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -80%)',
                    borderRadius: 1,
                    overflow: 'hidden',
                    userSelect: 'none'
                }}>
                <AddCreditFlow
                    handleClose={handleClose}
                    onCreditAdded={handleAddCredit}
                />
            </Box>
        </Modal>
    )
}
