import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const wavesurferRef = React.useRef(null);
  const {media, onReady, startTime, vjsTrigger, onCursorMove, setTranscriptAutoScroll, reportMediaDuration} = props;

  const videojsoptions = {
    controls: true,
    autoplay: false,
    loop: false,
    muted: false,
    fluid: true,
    inactivityTimeout: 0,
    bigPlayButton: false,
    playbackRates: [1, 1.5, 2, 2.5, 3],
    plugins: {
        wavesurfer: {
            height: 256,
            backend: 'MediaElement',
            displayMilliseconds: false,
            waveColor: '#3874cb',
            progressColor: '#757575', // grey.600
            cursorColor: 'black',
            hideScrollbar: false,
            normalize: true,
            container: '#waveform',
            scrollParent: true,
            // plugins: [
            //     WaveSurfer.regions.create({
            //         maxRegions: 1,
            //         dragSelection: {
            //             slop: 5
            //         }
            //     })
            // ]
        }
    }
}

  React.useEffect(() => {
    if (!media) return;
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
      const videoElement = document.createElement("video-js");

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const wavesurferElement = document.createElement("div");
      wavesurferElement.setAttribute('id', 'waveform')
      wavesurferRef.current.appendChild(wavesurferElement);
      wavesurferElement.onclick = () => setTranscriptAutoScroll(true)

      const player = playerRef.current = videojs(videoElement, videojsoptions, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
      });
      player.on('playing', () => setTranscriptAutoScroll(true) )
      player.on('seeked', (e) => onCursorMove(e.target.player.currentTime()))
      player.on('timeupdate', (e) => {
        if (e.target.player.currentTime) {
          onCursorMove(e.target.player.currentTime())
        }
      })
      player.on('loadedmetadata', () => {
        reportMediaDuration(player.duration());
      })
      player.src(media.getBlobUrlSrc())

    // You could update an existing player in the `else` block here
    // on prop change, for example:
    } else {
      const player = playerRef.current;
      player.src(media.getBlobUrlSrc())
    }
  }, [media, videoRef, onReady]);

  React.useEffect(() => {
    const player = playerRef.current;
    if (startTime) {
      player.currentTime(startTime)
      player.autoplay(false)
    }
  }, [vjsTrigger, startTime])

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
      <div ref={wavesurferRef} />
    </div>
  );
}

export default VideoJS;